<template>
  <div class="feedback-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog title="现场图片" :visible.sync="dialogVisible" width="50%">
      <img class="feedback-list__img" :src="imgSrc" alt="图片" />
      <el-pagination small layout="prev, pager, next" :total="imgCount" :page-size="1" @current-change="currentChange">
      </el-pagination>
    </el-dialog>
    <el-dialog title="短信提醒" :visible.sync="messageDialogVisible" :close-on-click-modal="false">
      <div class="SMS-reminder">
        <el-form ref="messageForm" :model="messageForm" :rules="rules" label-width="140px">
          <el-form-item label="提醒人姓名" prop="name">
            <label slot="label"><span style="color:red">*</span>&nbsp;&nbsp;提醒人姓名</label>
            <el-input placeholder="请输入提醒人姓名" v-model="messageForm.name" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="提醒人手机号码" prop="phone">
            <label slot="label"><span style="color:red">*</span>&nbsp;&nbsp;提醒人手机号码</label>
            <el-input placeholder="请输入提醒人手机号码" maxlength="11" v-model="messageForm.phone" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="短信模板" prop="describe" class="textarea">
            <el-input type="textarea" maxlength="300" disabled placeholder="请输入安装位置" v-model="messageForm.describe"
              clearable>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" style="width:140px" @click="submit()">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="意见回复" :visible.sync="opinionDialogVisible" :close-on-click-modal="false" @close="cancelReply()">
      <el-form :model="opinionForm" ref="opinionForm" class="opinion-input">
        <el-form-item prop="content">
          <el-input type="textarea" :rows="4" v-model="opinionForm.content" placeholder="请输入（0/120）" maxlength="120"
            show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div style="color:gray">用户在小程序-我的-我的消息中查看回复。</div>
      <div class="reply-btn">
        <el-button @click="cancelReply()">取消</el-button>
        <el-button type="primary" @click="submitReply()">确认回复</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看回复" :visible.sync="checkDialogVisible" :close-on-click-modal="false"
      @close="checkDialogVisible = false">
      <el-form :model="checkForm" class="opinion-input">
        <el-form-item prop="content">
          <el-input type="textarea" v-model="checkForm.content" :rows="4" show-word-limit disabled></el-input>
        </el-form-item>
      </el-form>
      <div>
        <span style="margin-right:20px">回复人：{{ replyName }}</span>
        <span>回复时间：{{ replyDate }}</span>
      </div>
      <div class="reply-btn">
        <el-button @click="checkDialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
let validName = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //姓名支持输入中英文字母，汉字，1~16个字符
    let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
    if (!reg.test(value)) {
      callback(new Error("姓名格式不对"));
    } else {
      callback();
    }
  }
};
let validMobile = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(value)) {
      callback(new Error("手机号格式不对"));
    } else {
      callback();
    }
  }
};
let validMessageName = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback("请输入提醒人姓名");
  } else {
    //姓名支持输入中英文字母，汉字，1~12个字符
    let reg = /^[a-zA-Z\u4e00-\u9fa5]{2,12}$/;
    if (!reg.test(value)) {
      callback(new Error("提醒人姓名格式不对"));
    } else {
      callback();
    }
  }
};
let validMessagePhone = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback("请输入提醒人手机号码");
  } else {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(value)) {
      callback(new Error("提醒人手机号码格式不对"));
    } else {
      callback();
    }
  }
};
export default {
  data() {
    let _this = this;
    return {
      messageForm: {
        name: '',
        phone: '',
        describe: '模板：【威富】云数平台收到一条意见反馈，房客（郑丫丫，13515548758），来自（深圳院子1栋101房间），请及时处理。'
      },
      rules: {
        name: [{
          validator: validMessageName,
          trigger: ['blur']
        }],
        phone: [{
          validator: validMessagePhone,
          trigger: ['blur']
        }],
      },
      messageDialogVisible: false,
      imgSrc: "",
      imgList: [],
      imgCount: 2,
      dialogVisible: false,
      opinionDialogVisible: false,
      opinionId: '',
      userId: '',
      role: '',
      checkDialogVisible: false,
      opinionForm: {
        content: ''
      },
      checkForm: {
        content: ''
      },
      replyName: '',
      replyDate: "",
      opt: {
        title: "意见反馈",
        search: [{
          key: "realname",
          label: "姓名",
          maxlength: 16,
          rules: [{
            validator: validName,
            trigger: ['blur']
          }]
        },
        {
          key: "phonenum",
          label: "手机号",
          rules: [{
            validator: validMobile,
            trigger: ['blur']
          }]
        },
        {
          key: "state",
          label: "状态",
          type: "select-no-filterable",
          opt: {
            list: [{
              label: "未处理",
              value: "0"
            },
            {
              label: "已处理",
              value: "1"
            }
            ]
          }
        }
        ],
        columns: [{
          label: "姓名",
          key: "realname"
        },
        {
          label: "角色",
          key: "roleStr"
        },
        {
          label: "电话",
          key: "phonenum"
        },
        {
          label: "提交时间",
          key: "createTimeStr"
        },
        {
          label: "房源",
          key: "roomInfoStr"
        },
        {
          label: "反馈描述",
          key: "description",
          width: "350"
        },
        {
          label: "现场图片",
          key: "picList",
          type: "action-but-img",
          opt: {
            list: [{
              label: "查看",
              on(row) {
                _this.imgSrc = row.picList[0];
                _this.imgList = row.picList;
                _this.imgCount = row.picList.length;
                _this.dialogVisible = true;
              }
            }]
          }
        },
        {
          label: "是否允许三天内联系",
          key: "contactStr",
          width: "180"
        },
        {
          label: "状态",
          key: "stateStr",
          type: "action-alt",
          opt: {
            activeText: "已处理",
            inactiveText: "未处理",
            on(row, item) {
              _this.$confirm('标记该状态已处理', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                let dto = {
                  id: row.id
                }
                _this.post("/user-service/feedback/updateFeedbackState", dto, {
                  isUseResponse: true,
                  isNotShowError: true
                }).then(res => {
                  if (res.data.code == 0) {
                    row[item.key] = true;
                    _this.$message({
                      showClose: true,
                      message: "修改成功!",
                      type: "success"
                    });
                  } else {
                    _this.$message({
                      showClose: true,
                      message: "修改失败!",
                      type: "error"
                    });
                  }
                }).catch(res => {
                  _this.$message({
                    showClose: true,
                    message: "修改失败!",
                    type: "error"
                  });
                });
              });
            }
          }
        },
        {
          label: "操作",
          key: "action",
          type: "action-back",
          opt: {
            list: [
              {
                label: "回复",
                on(row) {
                  _this.opinionId = row.id
                  _this.userId = row.userId
                  _this.role = row.role;
                  _this.opinionDialogVisible = true
                }
              },
              {
                label: "查看回复",
                on(row) {
                  _this.checkDialogVisible = true
                  _this.checkReply(row.id)
                }
              }
            ]
          }
        }
        ],
        buttons: [{
          type: 0,
          icon: 'question',
          name: '短信提醒',
          on() {
            let message = _this.messageForm.phone + ',' + _this.messageForm.name;
            if (_this.$refs["messageForm"]) {
              _this.$refs["messageForm"].resetFields(); //初始化表单
            }
            var dto = {
              configKey: 'sys_sms'
            }
            _this.post("/landlord-service/sysConfig/selectByKey", dto, {
              isUseResponse: true
            }).then(res => {
              let arr = res.data.data.split(',');
              _this.messageForm.phone = arr[0]
              _this.messageForm.name = arr[1]
              _this.messageDialogVisible = true;
            })
          }
        }]
      }
    };
  },
  created() {
    console.log("feedback-list created!");
  },
  deactivated() {
    this.dialogVisible = false;
  },
  methods: {
    onGet(opt) {
      var dto = {
        pageNum: opt.skip,
        pageSize: opt.limit
      };
      if (opt.searchForm.realname) {
        dto.realname = opt.searchForm.realname;
      }
      if (opt.searchForm.phonenum) {
        dto.phonenum = opt.searchForm.phonenum;
      }
      if (opt.searchForm.state) {
        dto.state = Number(opt.searchForm.state);
      }
      this.post("/user-service/feedback/listPagesFeedback", dto, {
        isUseResponse: true
      }).then(res => {
        if (res.data.code == 0) {
          for (var i = 0; i < res.data.data.length; i++) {
            var item = res.data.data[i];
            item.roleStr = ["房客", "房东", "房管员"][item.role];
            item.stateStr = [false, true][item.state]; //0待处理  1已处理
            item.contactStr = ["不允许", "允许"][item.contact];
            item.createTimeStr = moment(item.createTime).format("YYYY-MM-DD");
            item.roomInfoStr = item.roomInfo ? item.roomInfo : '--';
            if (item.isReply == 0) {
              item.action = [true, false]
            } else if (item.isReply == 1) {
              item.action = [false, true]
            }
          }
        }
        opt.cb(res.data);
      });
    },
    currentChange(idx) {
      this.imgSrc = this.imgList[idx - 1];
    },
    submit() {
      let _this = this;
      this.$refs['messageForm'].validate((valid) => {
        if (valid) {
          var dto = {
            configKey: 'sys_sms',
            configValue: this.messageForm.phone + ',' + this.messageForm.name,
          };
          this.post('/landlord-service/sysConfig/save-or-update', dto).then(res => {
            _this.$message({
              showClose: true,
              message: '提交成功！',
              type: 'success',
            })
            _this.messageDialogVisible = false;
          });
        }
      })
    },
    cancelReply() {
      this.opinionDialogVisible = false
      this.$refs['opinionForm'].resetFields();
    },
    submitReply() {
      if (!this.opinionForm.content) {
        this.$message({
          message: '回复内容不得为空',
          type: "warning"
        });
        return
      }
      let dto = {
        feedbackId: this.opinionId,
        userId: this.userId,
        content: this.opinionForm.content,
        role: this.role
      }
      this.post("/user-service/v1/feedback-reply/save-feedback", dto, {
        isUseResponse: true
      }).then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: '回复成功',
            type: "success"
          });
          this.$refs.myList.get();
          this.opinionDialogVisible = false
        }
      })
    },
    checkReply(val) {
      this.post("/user-service/v1/feedback-reply/query/detail?feedbackId=" + val, {
        isUseResponse: true
      }).then(res => {
        this.checkForm.content = res.content
        this.replyName = res.replyName ? res.replyName : '--'
        this.replyDate = res.replyDate ? res.replyDate : '--'
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.feedback-list__img {
  width: 100%;
}

.dialog-footer {
  width: 100%;
  text-align: center;
}

.opinion-input {
  margin: -30px 0 -15px;
}

.reply-btn {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
</style>
<style lang="scss">
.SMS-reminder {
  width: 90%;
  display: inline-block;
  margin: auto;

  .editTitle {
    display: inline-block;
    padding-right: 10px;
    text-align: right;
    width: 101px;
    color: #606266
  }

  .textarea {
    // width: 310px;
    height: 80px;
    border-color: #dcdfe6;
    color: #303133;

    textarea {
      width: 100%;
      height: 100%;
      resize: none;
    }
  }

}
</style>